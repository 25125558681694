



























import { Component, Prop, Vue } from 'vue-property-decorator';
import Calculator from '@/components/Calculator.vue';
import ViewTitle from '@/components/ViewTitle.vue';

export default Vue.extend({
    components: {
        Calculator,
        ViewTitle,
    },
});
