






































































import { Component, Prop, Vue } from 'vue-property-decorator';

export default Vue.extend({
    data() {
        return {
            nav: 5000000,
            ltv: 60,
            loan: 3000000,
            apr: 2.25,
            loan_period_yr: 20,
            loan_period_month: 0,
            payment: 1000,
            payment_schedule: [] as any,
            calculating: false,
        };
    },
    mounted() {
        this.calcLoan();
    },
    methods: {
        formatNumber(num: number) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        },
        calcLoan() {
            this.loan = Math.round(this.nav * this.ltv) / 100;
            this.calcPayment();
        },
        calcPayment() {
            this.calculating = true;
            try {
                const apr = this.apr / 1200;
                const term = Math.round(12 * this.loan_period_yr) + Math.round(this.loan_period_month);
                console.log('loan=' + this.loan);
                console.log('apr=' + this.apr + ',' + apr);
                console.log('term=' + term);
                const num = (this.loan * apr) / (1 - Math.pow((1 + apr), -term));
                this.payment = Math.ceil(num * 100) / 100;

                const schedule = [];
                let year = 1;
                let month = 1;
                let interest = 0;
                let principal = 0;
                let outstanding = this.loan;
                let i = 0;
                for (i = 0; i < term; i++) {
                    interest = Math.round(outstanding * apr * 100) / 100;
                    if (i === term - 1) {
                        principal = Math.round(outstanding * 100) / 100;
                        outstanding = 0;
                    } else {
                        principal = Math.round((this.payment - interest) * 100) / 100;
                        outstanding = Math.round((outstanding - principal) * 100) / 100;
                    }

                    const periodCnt = i + 1;
                    schedule.push({
                        // period: '第' + year.toString() + '年第' + month.toString() + '月',
                        period: periodCnt.toString(),
                        interest: this.formatNumber(interest),
                        principal: this.formatNumber(principal),
                        outstanding: this.formatNumber(outstanding),
                        });
                    month++;

                    if (month > 12) {
                        year++;
                        month = 1;
                    }
                }

                this.payment_schedule = schedule;
            } finally {
                this.calculating = false;
            }
        },
    },
});
